import React from 'react';

const Offenlegungspflicht = () => {
  const handleButtonClick = () => {
    const confirmMessage = 'Sie verlassen diese Webseite und werden auf www.lvm.de umgeleitet';
    if (window.confirm(confirmMessage)) {
      window.location.href = 'https://agentur.lvm.de/ranft/1';
    }
  };

  return (
    <div style={{marginLeft: '6%', marginTop: '3%'}}>
      <h2>Nachhaltigkeitsbezogene Offenlegungspflichten</h2>
      <div>
        <p>Die gesetzlich geforderten Informationen zu nachhaltigkeitsbezogenen Offenlegungspflichten <br />
        im Finanzdienstleistungssektor finden Sie unter:</p> 
        <button style={{marginBottom:'25%'}}onClick={handleButtonClick}>agentur.lvm.de/ranft</button>
      </div>
    </div>
  );
};

export default Offenlegungspflicht;
