import React, { useState } from 'react';
import '../Topbar.css';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

const Navbar = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav>
      {isMobile ? (
        <React.Fragment>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="menu-icon" onClick={handleMenuToggle}>
            {isMenuOpen ? (
              <div style={{ position: 'absolute', top: '30px', right: '10px' }}>
                <CloseIcon />
              </div>
            ) : (
              <MenuIcon />
              )}
              </div>
             {isMenuOpen && (
               <div>
               <ul className="links-mobile"onClick={handleMenuToggle}>
                 <li><a href="/">Start</a></li>
                 <li><a href="/#kontakt">Kontakt</a></li>
                 <li><a href="/#referenzen">Referenzen</a></li>
                 <li><a href="/#überuns">Über uns</a></li>
                 <li><a href="/#rezensionen">Rezensionen</a></li>
                 <Link style={{color:'#000000', textDecoration:'none',fontSize:'16px', marginBottom: '15px', fontWeight: '600', marginRight: '10px', marginTop: '10px'}} to="/stellenanzeigen">Jobs</Link>
                <br /> <br />
                <li>Weitere Links:</li>
                <Link style={{color:'#000000', textDecoration:'none',fontSize:'16px', marginBottom: '15px', fontWeight: '600', marginRight: '10px'}} to="/impressum">Impressum</Link>
                <Link style={{color:'#000000', textDecoration:'none',fontSize:'16px', marginBottom: '15px', fontWeight: '600', marginRight: '10px'}} to="/datenschutz">Datenschutz</Link>
                <Link style={{color:'#000000', textDecoration:'none',fontSize:'16px', marginBottom: '15px', fontWeight: '600', marginRight: '10px'}} to="/offenlegungspflicht">Offenlegungspflicht</Link>
   
               </ul>
               </div>
             )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ul className="links">
            <li><a href="/">Start</a></li>
            <li><a href="/#kontakt">Kontakt</a></li>
            <li><a href="/#überuns">Über uns</a></li>
            <li><a href="/#rezensionen">Rezensionen</a></li>
            <Link style={{color:'#000000', textDecoration:'none',fontSize:'16px', marginTop: '10px', fontWeight: '600', marginRight: '-170px', marginLeft: '10px'}} to="/stellenanzeigen">Jobs</Link>
          </ul>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="contact-button">
            <Button
              style={{
                textTransform: 'none',
                color: 'black',
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '20px',
                padding: '7px 15px',
                marginRight: '20px'
              }}
            >
              <a href="#kontakt" style={{ textDecoration: 'none', color: 'black' }}>
                Jetzt in Kontakt treten
              </a>
            </Button>
          </div>
        </React.Fragment>
      )}
    </nav>
  );
};

export default Navbar;
