 import React from 'react';
import startPicture from '../../assets/Green Lawn Care Facebook Cover.png';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@react-hook/media-query';
import startPictureMobile from '../../assets/Wir sind für Sie da wenn es darauf ankommt..png';

const DivContainer = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    !isMobile ? (
    <div style={{ position: 'relative' }}>
      <img src={startPicture} width="100%" alt='picture' />


      {/* Headline */}
      <div style={{ fontStyle:'normal', fontSize: '2rem',position: 'absolute', top: '39%', left: '25%', transform: 'translate(-50%, -50%)', display: 'flex', alignItems: 'center' }}>
        <div style={{marginLeft:'40px'}}>
          <h1 style={{fontWeight: '640'}}>Wir sind <span style={{color:'white'}}>für Sie da</span> <br />
          wenn es darauf <br />
          ankommt. 
          </h1> 
          <h4 style={{ fontWeight: '550'}}>Wir sind die LVM Agentur Ranft & Team </h4> 
          
        
          
          <a href="#kontakt"><Button
          style={{ marginRight: '10px', marginTop: '40px', backgroundColor: '#181136', color:'white', width:'170px', height:'48px', fontSize: '1.2rem', borderRadius: '10px' , textTransform: 'none'}}
          endIcon={<ArrowForwardIcon style={{ color: 'white' }} />}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = 'green';
         
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#181136';
         }}
        >
          Kontakt
        </Button>
        </a>
        <a href="#überuns"><Button
          style={{ marginRight: '20px', marginTop: '40px',  color:'#181136', width:'160px', height:'38px', fontSize: '1.2rem', textTransform: 'none' }}>
          mehr über Uns
        </Button>
        </a>
       
        </div>
        <div>
        
        </div>
    </div>
    </div>
    ) : 
    (
      <div style={{ position: 'relative' }}>
      <img src={startPictureMobile} width="100%" alt='picture' />
      </div>
    )
  );
};

export default DivContainer;
