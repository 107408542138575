import React from 'react'
import backgroundImage from '../../assets/250124_LVM_HB_Team.jpg'
import { useMediaQuery } from '@react-hook/media-query';
import { Link } from 'react-router-dom';
const BewerbungsContainer = () => {

  const linkStyle = {
    color: 'black',
    background: 'linear-gradient(to right, #FFFFFF, #B2B5AB)',
    borderRadius: '30px',
    height: '30px',
    padding: '15px',
    width: '150px', // Breite angepasst
    textDecoration: 'none',
    transition: 'background-color 0.3s ease',
    "&:hover": {
      backgroundColor: '#00FF00',
    },
  };
  
      
    const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div>
       
        {isMobile && (
        <div style={{textAlign:'center'}}>
            <h3>Werde jetzt ein Teil von Uns</h3>
            Bewirb Dich und werde ein Teil von unserem #Team
            <br />
            <div style={{marginTop:'50px'}}>
            <Link style={{  color: 'black',
    background: 'linear-gradient(to right, #7CA40F, #006E12)',
    borderRadius: '30px',
    height: '40px',
    padding: '12px',
    width: '150px', // Breite angepasst
    textDecoration: 'none',
    transition: 'background-color 0.3s ease',}} to="/stellenanzeigen">Stellen entdecken</Link>
            </div> 
       </div> 
       )}

{!isMobile && (
  <div>
    <div style={{ textAlign: 'center' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', marginTop:'120px'}}>
        <h1 style={{fontSize: '3.2rem', marginTop: '120px', color: '#181136'}}>
          Werde jetzt ein Teil von Uns
        </h1>
        <h2 style={{color: '#181136', marginTop: '-20px', fontWeight: 'normal'}}> Bewirb Dich und werde ein Teil von unserem #Team <br /> <br /></h2>  
          <Link style={linkStyle} to="/stellenanzeigen">Stellen entdecken</Link>
      </div>
    </div>
    <img src={backgroundImage} alt="image" width={'100%'} />
  </div>
  </div>
)}





    </div>
  )
}

export default BewerbungsContainer